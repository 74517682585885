.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0;
  background: #5e5f79;
  padding: 0 60px;
  // transition: all ease-in-out 500ms;

  &.show {
    height: 44px;
  }

  &::before {
    content: "";
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-family: Microsoft YaHei;
    color: #fff;

    .download {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      margin: 0 4px;
      padding: 6px 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 3px;
      cursor: pointer;
      font-size: 12px;
      transition: all ease 300ms;

      &:hover {
        border-color: #fff;
        color: #fff;
      }

      .icon {
        width: 12px;
        height: 13px;
        background: url("/images/components/download.svg") center center no-repeat;
        background-size: 12px auto;
        margin-right: 4px;
      }
    }
  }

  .close {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url("/images/components/close.svg") center center no-repeat;
    background-size: 13px 13px;
    cursor: pointer;
  }
}
